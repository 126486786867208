import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    location: {
      lng: null,
      lat: null,
    },
		current_nav: null
  },
  mutations: {
    updateLocation (state, payload) {
      state.location = payload.location
    },
		updateCurrentNav (state, payload) {
      state.current_nav = payload
    }
  },
  getters: {
    location: function (state) {
      return state.location
    },
		currentNav: function(state) {
			return state.current_nav
		}
  }
})

export default store
