import Vue from 'vue'
import Router from 'vue-router'
import state from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'PC',
			redirect: '/home',
      component: resolve => require(['@/views/pc/home/Index'], resolve),
			children: [
				{
					path: '/index',
					name: 'Index',
					component: resolve => require(['@/views/pc/index/Index'], resolve),
				},
				{
					path: '/super',
					name: 'SuperMall',
					component: resolve => require(['@/views/pc/supermall/Index'], resolve),
				},
				{
					path: '/home',
					name: 'PcHome',
					component: resolve => require(['@/views/pc/intro/Index'], resolve),
				},
				{
					path: '/descover',
					name: 'Descover',
					component: resolve => require(['@/views/pc/descover/Index'], resolve),
				},
				{
					path: '/goods_article',
					name: 'GoodsArticle',
					component: resolve => require(['@/views/pc/goods_article/Index'], resolve),
				},
				{
					path: '/goods_article_item',
					name: 'GoodsArticleItem',
					component: resolve => require(['@/views/pc/goods_article/Item'], resolve),
				},
				{
					path: '/goods_list',
					name: 'GoodsList',
					component: resolve => require(['@/views/pc/goods/Index'], resolve),
				},
				{
					path: '/goods_item',
					name: 'GoodsItem',
					component: resolve => require(['@/views/pc/goods/Item'], resolve),
				},
			]
    },
		{
			path: '/shop',
			name: 'shop',
			component: resolve => require(['@/views/shop/Index.vue'], resolve),
			meta: {
				title: '淘街坊商城',
				keepAlive: true
			}					
		},
		{
			path: '/classify',
			name: 'classify',
			component: resolve => require(['@/views/shop/Classify.vue'], resolve),
			meta: {
				title: '热门分类',
				keepAlive: true
			}	
		},
		{
			path: '/personal',
			name: 'personal',
			component: resolve => require(['@/views/personal/Index.vue'], resolve),
			meta: {
				title: '个人中心',
				keepAlive: true
			}	
		},
    {
      path: '/home_moblie',//移动端下载页面
      name: 'MobileHome',
      component: resolve => require(['@/views/mobile/home/Index'], resolve),
    },
    {
      path: '/tl/:code', //转链跳转
      name: 'MobileTl',
      component: resolve => require(['@/views/mobile/tl/Index'], resolve),
    },
    {
      path: '/invitecode',//邀请码
      name: 'Invitecode',
      component: resolve => require(['@/views/invitation/Invitecode'], resolve),
    },
    {
			path: '/GoodsRecommend',
			name: 'GoodsRecommend',
      component: resolve => require(['@/views/shop/Goods_recommend'], resolve),
      meta: {
        title: '商品推荐',
        needJssdk: true,
        keepAlive: true
      }
    },
    {
      path: '/SG',
      component: resolve => require(['@/views/shop/SG'], resolve),
      meta: {
        title: '淘街坊'
      }
    },
    {
      path: '/wallet',
      component: resolve => require(['@/views/personal/wallet/Wallet'], resolve),
      meta: {
        title: '钱包',
        requiresAuth: true
      }
    },
    {
      path: '/goodsDetail',
      component: resolve => require(['@/views/shop/goods_detail.vue'], resolve),
      meta: {
        title: '购物返利',
        needJssdk: true,
        requiresAuth: true
      }
    },
    {
      path: '/myOrder',
      component: resolve => require(['@/views/personal/wallet/myOrder'], resolve),
      meta: {
        title: '我的订单',
        requiresAuth: true
      }
    },
    {
      path: '/identityCard',
      component: resolve => require(['@/views/personal/wallet/identityCard'], resolve),
      meta: {
        title: '申请提现',
        requiresAuth: true
      }
    },
    {
      path: '/withdrawCashApply',
      component: resolve => require(['@/views/personal/wallet/WithdrawCashApply'], resolve),
      meta: {
        title: '申请提现',
        requiresAuth: true
      }
    },
    {
      path: '/uploadSuccess',
      component: resolve => require(['@/views/personal/wallet/uploadSuccess'], resolve),
      meta: {
        title: '申请提现',
        requiresAuth: true
      }
    },
    {
      path: '/withdrawCash',
      component: resolve => require(['@/views/personal/wallet/WithdrawCash'], resolve),
      meta: {
        title: '提现记录',
        requiresAuth: true
      }
    },
    {
      path: '/meituan',
      component: resolve => require(['@/views/meituan/Index'], resolve),
      meta: {
        title: '美团外卖大红包',
        requiresAuth: true
      }
    },
    {
      path: '/eleme',
      component: resolve => require(['@/views/eleme/Index'], resolve),
      meta: {
        title: '饿了么大红包',
        requiresAuth: true
      }
    },
    {
      path: '/about',
      component: resolve => require(['@/views/personal/About'], resolve),
      meta: {
        title: '关于「淘街坊」',
        requiresAuth: false
      }
    }, {
      path: '/html',
      component: resolve => require(['@/views/common/Html'], resolve)
    }, {
      path: '/login',
      component: resolve => require(['@/views/auth/Login'], resolve),
      meta: {
        title: '登录'
      }
    }, {
      path: '/reg',
      component: resolve => require(['@/views/auth/Reg'], resolve),
      meta: {
        title: '注册'
      }
    }, {
      path: '/findpwd',
      component: resolve => require(['@/views/auth/FindPwd'], resolve),
      meta: {
        title: '找回密码'
      }
    }, {
      path: '/bindPhone',
      component: resolve => require(['@/views/auth/BindPhone'], resolve),
      meta: {
        title: '绑定手机号',
        requiresAuth: false
      }
    },
    {
      path: '/invite-input',
      component: resolve => require(['@/views/invite/Input'], resolve),
      meta: {
        title: '输入邀请码...',
        needJssdk: false,
        requiresAuth: false
      }
		},
		{
      path: '/history',//邀请码
      name: 'history',
			component: resolve => require(['@/views/history/Index'], resolve),
			meta: {
        title: '足迹👣',
        needJssdk: false,
        requiresAuth: true
      }
    },
    {
      path: '/help-bargain/:id',
      component: resolve => require(['@/views/bargain/bargain'], resolve),
      meta: {
        title: '砍价免费拿 包邮送到家'
      }
    },
    {
      path: '/red-pocket/:code',
      component: resolve => require(['@/views/red-pocket/red-pocket'], resolve),
      meta: {
        title: '瓜分红包'
      }
    },
    {
      path: '/article/:id',
      component: resolve => require(['@/views/article/article'], resolve),
      meta: {
        title: ''
      }
    },
    {
      path: '/article-app/:id',
      component: resolve => require(['@/views/article/article-app'], resolve),
      meta: {
        title: ''
      }
    },
    {
      path: '/lottery/:id',
      component: resolve => require(['@/views/lottery/lottery'], resolve),
      meta: {
        title: '抽奖',
      requiresAuth: true
      }
    },
    {
      path: '/lottery-award/:id',
      component: resolve => require(['@/views/lottery/lottery-award'], resolve),
      meta: {
        title: '抽奖',
      requiresAuth: true
      }
    },
    {
      path: '/attention',
      component: resolve => require(['@/views/lottery/attention'], resolve),
      meta: {
        title: '关注公众号'
      }
    },
		{
      path: '/alipage',
      component: resolve => require(['@/views/alipage/ToTaoBao'], resolve)
    },
    {
      path: '*',
      redirect: '/home'
    }
  ]
})

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach((to, from, next) => {
  if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    if (to.name == 'PcHome') {
      return next({ name: 'MobileHome', query: to.query})
    }
  } else {
    if (to.name == 'MobileHome') {
      return next({ name: 'PcHome', query: to.query })
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }

  let invite_code = to.query.inviteCode
  if (!!invite_code) {
    localStorage.setItem('invite-code', invite_code);
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user-token')) {
      next()
    } else {
      setTimeout(() => {
        if (localStorage.getItem('user-token')) {
          next()
        } else {
          Vue.cookie.set('beforeLoginUrl', to.fullPath) // 保存用户进入的url
          next('/login')
        }
      }, 1000);
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
	state.commit('updateCurrentNav', to.name)
  if (process.env.NODE_ENV === "development") {
    // console.log('开发环境')
  } else {
    setTimeout(() => {
      var _hmt = _hmt || [];
      (function () {
        //每次执行前，先移除上次插入的代码
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?22af3b8525b600a05f5600081cb8a59b";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    }, 0);
  }
});

export default router
