import Vue from 'vue'

function clear () {
  localStorage.clear()
}

function getToken () {
	// return Vue.cookie.get('user-token')
  return localStorage.getItem('user-token')
}

function setToken (token) {
  localStorage.setItem('user-token', token)
}

function getUserInfo () {
  return JSON.parse(localStorage.getItem('userInfo'))
}

function setUserInfo (userInfo) {
  localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

function setUserAmount (amount) {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  userInfo.amount = amount
  localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

function getUserAmount () {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (!!userInfo && !!userInfo.amount) {
    return userInfo.amount
  }
  return 0
}

function setUserTnl (tnl) {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  userInfo.tnl = tnl
  localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

function getUserTnl () {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (!!userInfo && !!userInfo.tnl) {
    return userInfo.tnl
  }
  return 0
}

function getNickName () {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (!!userInfo && !!userInfo.nickname) {
		return userInfo.nickname
  }else if(!!userInfo && !!userInfo.phone){
		return userInfo.phone
	}

  return '未登录'
}

function getAvatar () {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (!!userInfo && !!userInfo.avatar) {
    return userInfo.avatar
  }
  // 头像为空（手机登录时）
  return require('@/assets/personal/avatar.png')
}

function setUserDefaultAddress (address) {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  userInfo.default_address = address
  localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

function getUserDefaultAddress () {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))

  if (userInfo.default_address) {
    return userInfo.default_address
  }
  return ''
}

function setOrderGoods (data) {
  localStorage.setItem('orderGoods', JSON.stringify(data))
}

function getOrderGoods () {
  return JSON.parse(localStorage.getItem('orderGoods'))
}

function setCartCount (number) {
  localStorage.setItem('cartCount', parseInt(number))
}

function getCartCount () {
  return localStorage.getItem('cartCount')
}

function setLocalCart (item) {
  let cartList = JSON.parse(localStorage.getItem('cartList'))
  let flag = true
  for (let i in cartList) {
    if (cartList[i].sku_id === item.sku_id) {
      cartList[i].amount += parseInt(item.amount)
      flag = false
    }
  }

  if (flag) {
    if (!cartList) {
      cartList = []
    }
    cartList.push(item)
  }

  localStorage.setItem('cartList', JSON.stringify(cartList))
  return cartList
}

function getLocalCart () {
  let cartList = JSON.parse(localStorage.getItem('cartList'))
  if (!cartList) {
    return []
  }
  return cartList
}

function changeLocalCart (item) {
  let cartList = JSON.parse(localStorage.getItem('cartList'))
  for (let i in cartList) {
    if (cartList[i].sku_id === item.sku_id) {
      cartList[i].amount = parseInt(item.amount)
    }
  }

  localStorage.setItem('cartList', JSON.stringify(cartList))
  return cartList
}

function removeCart (skuIds) {
  let cartList = JSON.parse(localStorage.getItem('cartList'))
  if (!cartList) {
    return []
  }

  for (let j in skuIds) {
    for (let i in cartList) {
      if (cartList[i].sku_id === skuIds[j]) {
        cartList.splice(i, 1)
        break
      }
    }
  }

  localStorage.setItem('cartList', JSON.stringify(cartList))
  return cartList
}

function clearLocalCart () {
  localStorage.setItem('cartList', null)
}

function setCustomer (data) {
  localStorage.setItem('customer_data', JSON.stringify(data))
}

function getCustomer () {
  return JSON.parse(localStorage.getItem('customer_data'))
}

function setOrderPageTab (data) {
  localStorage.setItem('order_page_tab', data)
}

function getOrderPageTab () {
  return localStorage.getItem('order_page_tab')
}

function setReadyPayOrders (data) {
  localStorage.setItem('paid_flag', 'no')
  localStorage.setItem('ready_pay_orders', JSON.stringify(data))
}

function getReadyPayOrders () {
  return JSON.parse(localStorage.getItem('ready_pay_orders'))
}

function setPaidFlag (data) {
  localStorage.setItem('paid_flag', data)
}

function getPaidFlag () {
  return localStorage.getItem('paid_flag')
}

function setTmpToken (token) {
  localStorage.setItem('tmp_token', token)
}
function getTmpToken (token) {
  return localStorage.getItem('tmp_token')
}
export default {
  clear,
  getToken,
  setToken,
  getUserInfo,
  setUserInfo,
  setUserAmount,
  getUserAmount,
  setUserTnl,
  getUserTnl,
  getNickName,
  getAvatar,
  setUserDefaultAddress,
  getUserDefaultAddress,
  setOrderGoods,
  getOrderGoods,
  setCartCount,
  getCartCount,
  setLocalCart,
  getLocalCart,
  clearLocalCart,
  changeLocalCart,
  removeCart,
  setCustomer,
  getCustomer,
  setOrderPageTab,
  getOrderPageTab,
  setReadyPayOrders,
  getReadyPayOrders,
  setPaidFlag,
	getPaidFlag,
	setTmpToken,
	getTmpToken
}
