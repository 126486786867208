<template>
  <van-tabbar route v-model="active">
    <van-tabbar-item to="/shop" name="shop">
      <span :style="active=='shop' ? color.active : color.normal">商场</span>
      <img slot="icon" :src="active=='shop' ? mall_icon.active : mall_icon.normal" />
    </van-tabbar-item>

    <van-tabbar-item to="/classify" name="classify">
      <span :style="active=='classify' ? color.active : color.normal">分类</span>
      <img slot="icon" :src="active=='classify' ? cate_icon.active : cate_icon.normal" />
    </van-tabbar-item>

    <van-tabbar-item to="/personal" name="personal">
      <span :style="active=='personal' ? color.active : color.normal">我的</span>
      <img slot="icon" :src="active=='personal' ? personal_icon.active : personal_icon.normal" />
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import Vue from "vue";
import { Tabbar, TabbarItem } from "vant";
Vue.use(Tabbar).use(TabbarItem);

export default {
	name: "MenuBar",
	props: {
		page: {
			type: String,
			default: ""
		}
	},
	watch: {
		page: function (newVal, oldVal) {
			this.active = newVal
		}
	},
  data() {
    return {
			active: "",
      color: {
        normal: "color:#9B9B9B",
        active: "color:#FF4663",
      },
      mall_icon: {
        normal: require("@/assets/menu/icon_05_02_normal.png"),
        active: require("@/assets/menu/icon_05_02_active.png"),
      },
      cate_icon: {
        normal: require("@/assets/menu/four.png"),
        active: require("@/assets/menu/active_four.png"),
			},
      personal_icon: {
        normal: require("@/assets/menu/icon_05_03_normal.png"),
        active: require("@/assets/menu/icon_05_03_active.png"),
      }
    };
	},
	mounted() {
		this.active = this.page
	}
};
</script>

<style scoped>
.van-tabbar {
  background: #fff;
}
[class*="van-hairline"]::after {
  border-bottom: 0;
}
.van-tabbar-item--active {
  color: #f22d7f;
}
.van-tabbar-item__icon img {
  height: 30px;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .van-tabbar {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}
</style>
