import ls from '@/libs/localstore'
import Vue from 'vue'

window.axios = require('axios')
window.qs = require('qs')

window.axios.defaults.baseURL = 'http://app.taojiefang.cn/api'

// 请求拦截器
window.axios.interceptors.request.use(function (config) {
	config.headers['Authorization'] =  'Bearer ' + ls.getToken()

  if (config.method === 'post') {
    if (config.headers['Content-Type'] !== 'multipart/form-data') {
      config.data = window.qs.stringify(config.data)
    }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// 响应拦截器
window.axios.interceptors.response.use(function (response) {
	// 更新token
	if (response.headers.authorization) {
		let auth = response.headers.authorization
		let token = auth.substr(7)
		Vue.cookie.set('user-token', token, { expires: "7d" })
		ls.setToken(token)
	}

	// token有误
	if (response.data.errCode === 'ERR_02000') {
		// 清除token
		ls.setToken('')
		ls.setUserInfo('')
		Vue.cookie.delete('user-token')
	}
	return response
}, function (error) {
	return Promise.reject(error)
})

window._http = (url, params={}, method='post', cfg={}) => {
	let config = {
		url: url, 
		method: method
	}
	if(method == 'get')  config.params = params 
	if(method == 'post') config.data   = params
	Object.assign(config, cfg)
	return window.axios.request(config).then((response) => {
    return Promise.resolve(response.data)
  }).catch((error) => {
    if (error.response) {
      return Promise.reject(error.response.data) // err.response.data 对象里含有errMsg属性，服务器定义
		}
		if(error.message) {
			return Promise.reject({errMsg: error.message})
		}
    return Promise.reject(error)
  })
}
