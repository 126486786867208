<template>
  <div id="app">
		<transition name="fade-transform" mode="out-in" >
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>
		</transition>
		<transition name="fade-transform" mode="out-in">
			<router-view v-if="!$route.meta.keepAlive"></router-view>
		</transition>

		<!-- 菜单栏 -->
		<menu-bar :page="$route.name" v-if="__ShowMenu__"></menu-bar>
  </div>
</template>

<script>
import MenuBar from '@/components/MenuBar'

export default {
	name: "App",
	components: {
		MenuBar
	},
	computed: {
		__ShowMenu__() {
			const menus = {
				shop: true,
				classify: true,
				personal: true,
			};
			if(menus[this.$route.name]) {
				return true
			}
			return false
		}
	}
}
</script>

<style>
 html,
body {
  background: #f8f8f8;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
}
#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  font-family: "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Source Han Sans",
    "droid sans fallback", "Droid Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.4s ease-in-out;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page_header {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.page_body {
  flex: auto;
}
.copyright {
  height: 40px;
  color: #aaa;
  font-size: 14px;
}
.anchorBL {
  display: none;
}
.mt10 {
  margin-top: 10px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mt5 {
  margin-top: 5px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt50 {
  margin-top: 50px;
}
.mt100 {
  margin-top: 100px;
}
.mt15 {
  margin-top: 15px;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.p10 {
  padding: 10px;
}
.p05 {
  padding: 0 5px;
}
.p015 {
  padding: 0 15px;
}
.p020 {
  padding: 0 20px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt40 {
  padding-top: 40px;
}
.pt45 {
  padding-top: 45px;
}
.pt50 {
  padding-top: 50px;
}
.pt60 {
  padding-top: 65px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb50 {
  padding-bottom: 50px;
}
.pb60 {
  padding-bottom: 60px;
}
.pb80 {
  padding-bottom: 80px;
}
.tl {
  text-align: left;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
.f10 {
  font-size: 10px;
}
.f12 {
  font-size: 12px;
}
.f13 {
  font-size: 13px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
.f20 {
  font-size: 20px;
}
.f22 {
  font-size: 22px;
}
.f24 {
  font-size: 24px;
}
.f30 {
  font-size: 30px;
}
.f36 {
  font-size: 36px;
}
.f40 {
  font-size: 40px;
}
.f50 {
  font-size: 50px;
}
.f00 {
  color: #f40337;
}
.fc12 {
  color: #f22d7f;
}
.fcff {
  color: #fff;
}
.fc01 {
  color: #111;
}
.fc02 {
  color: #222;
}
.fc03 {
  color: #333;
}
.fc06 {
  color: #666;
}
.fc04 {
  color: #ef3d5b;
}
.fc07 {
  color: #9b9b9b;
}
.fff {
  color: rgb(255, 255, 255);
}
.b {
  font-weight: bold;
}
.van-nav-bar__title {
  font-size: 18px;
}
.van-tabbar-item__icon {
  margin-bottom: 0 !important;
}
.van-checkbox__icon--checked .van-icon {
  border-color: #f40337 !important;
  background-color: #f40337 !important;
}
.van-row {
  margin: 0 !important;
}
.van-notice-bar {
  font-size: 14px !important;
}
.van-ellipsis-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #000;
  /* background-color: #f40337; */
}


body {
  line-height: 1;
}

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
.clearfix {
  *zoom: 1;
}
</style>
