import './uni.webview'
window.Uni_SaveToken = function(token){
	localStorage.setItem('user-token', token)
}

window.Uni_Goback = function() {
	console.log(window.history.length)
	router.back()
}

document.addEventListener('UniAppJSBridgeReady', function() {
	window.Uni_SendMessage = function(action, data) {
		uni.postMessage({
			data: {
				action: action,
				data: data
			}
		});
	}
});