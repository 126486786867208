import Vue from 'vue'
import App from './App'
import router from './router'
import wxjssdk from 'weixin-js-sdk'
import store from './store'
import VueCookie from 'vue-cookie'
import '@/api/base'
import { Toast, Dialog } from 'vant'

Vue.config.productionTip = false
Vue.prototype.$wechat = wxjssdk

// 加载uniapp
import '@/utils/uniapp.js'

Vue.use(Toast).use(Dialog)
Vue.use(VueCookie)
Vue.config.ignoredElements = ['wx-open-launch-app']
 
// 创建Vue实例函数
new Vue({
  el: '#app',
  store,
  router,
  render: h => {
    return h(App)
  }
}).$mount('#app')
